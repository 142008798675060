/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2021-11-26 11:37:13
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */

class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.name = ''
      this.code = null
      this.state = ''
      this.sellPrice = ''
      this.costPrice = ''
      this.unit = ''
      this.pic = ''
    } else if (type === 'rule') {
      this.name = [
        {
          required: true,
          message: '商品名称不能为空',
          trigger: 'blur',
        },
      ]
      this.code = [
        {
          required: true,
          message: '编码不能为空',
          trigger: ['blur', 'change'],
        },
      ]
      this.sellPrice = [
        {
          required: true,
          message: '售价不能为空',
          trigger: ['blur', 'change'],
        },
      ]
      this.costPrice = [
        {
          required: true,
          message: '成本不能为空',
          trigger: ['blur', 'change'],
        },
      ]
      this.unit = [{
        required: true,
        message: '请选择单位',
        trigger: ['blur', 'change'],
      }]
      this.pic = [{
        required: true,
        message: '请选择图片',
        trigger: ['blur', 'change'],
      }]
    }
  }
}
export default searchFrom
